import React, { useMemo } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { type RootState } from '../../store/store'
import { useAppSelector } from '../../hooks/redux'
import useUserType from '../../hooks/UseUserType'
import Icon from '../../app/icon/Icon'
import { USER_ID_KEY } from '../../urls'

export default function LandlordFooter(): JSX.Element {
    const location = useLocation().pathname

    // useEffect(() => {
    //     // return {

    //     // }
    //     checkDocument(location)
    // }, [location])
    const storeUnits = useAppSelector((state: RootState) => state.units.units)
    const isUnitsBSOIds = storeUnits
        .filter((__unit) => __unit.attributes.ManagedBy === 'BSO')
        .map((_unit) => _unit.id)

    const currentUser = useAppSelector(
        (state: RootState) => state.user.currentUser
    )
    const userID = currentUser.id ?? window.localStorage.getItem(USER_ID_KEY)

    const viewings = useAppSelector((state: RootState) => state.viewings.viewings).filter(
        (el) => el?.attributes?.User?.data?.id && el?.attributes?.Unit?.data?.id != null && isUnitsBSOIds.includes(el?.attributes?.Unit?.data?.id)
    )
    const offers = useAppSelector((state: RootState) => state.offers.offers).filter(
        (el) => el?.attributes?.User?.data?.id && el?.attributes?.Unit?.data?.id != null && isUnitsBSOIds.includes(el?.attributes?.Unit?.data?.id) && (el?.attributes?.Type?.[0]?.Offer) // isUnitsBSOIds.includes(el?.attributes?.Unit?.data?.id)
    )
    const advertises = useAppSelector((state: RootState) => state.advertises.advertises).filter(
        (el) => {
            return el?.attributes?.User?.data?.id && el?.attributes?.Unit?.data?.id != null && isUnitsBSOIds.includes(el?.attributes?.Unit?.data?.id)
        }
    )
    const userDevice = useAppSelector((state) => state.app.userDevice)
    // const agentViewings = viewings.filter((el) => el?.attributes?.User?.data?.id === userID)
    const chatList = useAppSelector((state) => state.chatList.chatList)
    const allAttention = [...viewings, ...offers, ...advertises].filter((el: any) => {
        return el.attributes.Notify === 'BSO' || el.attributes.Notify === 'Both'
    })
    const chatAttentionCount = useMemo(() =>
        chatList
            .filter((chat) => chat?.userID != null)
            .reduce(
                (accumulator, chat) => {
                    if (chat.Unread != null) {
                        const keys = Object.keys(chat.Unread)
                        const unreadUsers = keys.filter(key => Number(key) === currentUser.id)
                        const unreadCount = unreadUsers.reduce(
                            (unreadAccumulator, key) => unreadAccumulator + (chat?.Unread?.[key] ?? 0),
                            0
                        )
                        return accumulator + unreadCount
                    } else {
                        return accumulator
                    }
                },
                0
            )
        , [chatList])

    const { userTypeLowercase } = useUserType()

    return (
        <>

            <div className={`footer fixed-bottom bg-white shadow-sm mw-450px mx-auto justify-content-between ${userDevice === 'IPhone' ? 'pb-6' : ''}`} style={{ zIndex: '199' }} >
                {/* <h1 className="marquee  m-0 p-0 bg-bso"><span>This is a marquee. Text, text, text...</span></h1> */}
                <div className="d-flex flex-row w-100 px-4 pb-4 justify-content-center" style={{ paddingTop: '1.86rem' }}>
                    {/* <Link
                        to={`/${userTypeLowercase}/magic-dashboard`}
                        //   onClick={() => useCheckDocument('/agent/dashboard')}
                        //   to={ `${statusesApproved.verifiedStatus ? useCheckDocument('/agent/dashboard') : useCheckDocument('#')}  `}
                        //  to={useCheckDocument('/agent/dashboard')}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/magic-dashboard` || location === `/${userTypeLowercase}/profile` ? 'text-dark' : 'text-gray-400'}`}
                    >

                        <div className="d-flex flex-column align-items-center">
                            <span className="bi bi-grid fs-4x m-0 p-0" />
                            <span className={`${location === `/${userTypeLowercase}/magic-dashboard` ? 'fw-bold' : 'fw-normal'} fs-4`}>Board</span>
                        </div>
                    </Link> */}
                    <Link
                        // to={ `${statusesApproved.verifiedStatus ? '/agent/schedule' : '#'}  `}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/schedule` ? 'text-dark' : 'text-gray-400'}`}
                        to={`/${userTypeLowercase}/schedule`}
                    // onClick={() => useCheckDocument('/agent/schedule')}
                    >
                        <div className="d-flex flex-column align-items-center position-relative">
                            {allAttention != null && allAttention.length > 0
                                ? (
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"

                                    >
                                        {allAttention.length}
                                    </span>
                                )
                                : null}

                            <Icon
                                name={'event_available'}
                                className={'fs-5x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/schedule` ? 'fw-bold' : 'fw-normal'} fs-4`}>Tasks</span>
                        </div>
                    </Link>
                    <Link
                        //  to={ `${statusesApproved.verifiedStatus ? '/agent/chats' : '#'}  `}
                        to={`/${userTypeLowercase}/chats`}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/chats` ? 'text-dark' : 'text-gray-400'}`}

                    >
                        <div className="d-flex flex-column align-items-center position-relative">
                            {chatAttentionCount > 0
                                ? (
                                    <span
                                        className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-danger"
                                    >
                                        {chatAttentionCount}
                                    </span>
                                )
                                : null}
                            <Icon
                                name={'chat'}
                                className={'fs-5x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/chats` ? 'fw-bold' : 'fw-normal'} fs-4`}>Chats</span>
                        </div>
                    </Link>
                    <Link
                        //  to={useCheckDocument('/agent/units/nav')}
                        to={`/${userTypeLowercase}/my_units`}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/my_units` ? 'text-dark' : 'text-gray-400'}`}

                    >
                        <div className="d-flex flex-column align-items-center">
                            <Icon
                                name={'home'}
                                className={'fs-5x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/my_units` ? 'fw-bold' : 'fw-normal'} fs-4`}>Units</span>
                        </div>
                    </Link>

                    <Link
                        // to={ `${statusesApproved.verifiedStatus ? useCheckDocument('/agent/menu') : useCheckDocument('#')}  `}
                        to={`/${userTypeLowercase}/menu`}
                        className={`text-decoration-none p-1 mx-4 ${location === `/${userTypeLowercase}/menu` ? 'text-dark' : 'text-gray-400'}`}

                    >
                        <div className="d-flex flex-column align-items-center ">
                            <Icon
                                name={'menu'}
                                className={'fs-5x'}
                                imageSize={48}
                            />
                            <span className={`${location === `/${userTypeLowercase}/menu` ? 'fw-bold' : 'fw-normal'} fs-4`}>Menu</span>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    )
}

{ /* <h1 className="marquee  m-0 p-0 bg-bso"><span>This is a marquee. Text, text, text...</span></h1> */ }
