import {store} from "../../../store/store";
import { USER_ID_KEY } from "../../../urls";

export default function renderOfferStatus (status: string, offerID: number ): string {
    const offer = store.getState().offers.offers.find(offerItem => offerItem.id === offerID)
    const offerStatuses = offer?.attributes?.Statuses
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const isMyUnit = offer?.attributes.AgentToAgentID  === Number(userID)
    if (status === 'Approved' && offer?.attributes?.AgentToAgentID == null) { return 'Sent to landlord' }


    if (offerStatuses?.includes('Negotiated by agent') && Number(userID) !== Number(process.env.REACT_APP_BSO_USER_ID)){ return isMyUnit ? 'Negotiated by other agent' : 'Negotiated by yourself' }
    if (offerStatuses?.includes('Negotiated by other agent')) { return isMyUnit ? 'Negotiated by yourself' : 'Negotiated by other agent' }
    if (offerStatuses?.includes('Canceled by other agent')){ return isMyUnit ? 'Canceled by yourself' : 'Canceled by agent' }   
    if (offerStatuses?.includes('Canceled') && Number(userID) !== Number(process.env.REACT_APP_BSO_USER_ID)){ return isMyUnit ? 'Canceled by agent' : 'Canceled by yourself' }
    if (offerStatuses?.includes('Canceled') && Number(userID) === Number(process.env.REACT_APP_BSO_USER_ID)){ return 'Canceled by agent' }
    return status
}
